.arrowBox {

    padding-bottom: 10px;
}

.lookArrow {
    color: var(--brand-accent2-medium);
    width: 50px;
    height: 50px;
}

.bounceDown {
    animation: bounceDownKey 3s ease .75s 100 none running;
}

.bounceUp {
    /*Do nothing*/
}

.arrowUp {
    transform: rotate(180deg);
}