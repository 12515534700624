/*Authored by Tyler Smalley*/

li {
    list-style-type: none;
}

.brandWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.brandWrapper div, .brandWrapper button {
    margin: 10px 0;
}

.topHeader, .bottomHeader {
    text-transform: uppercase;
    font-size: 10vh;
    font-family: "Megrim", system-ui;
    font-weight: bold;
    font-style: normal;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logoBox {
    box-shadow: var(--bg-shadowbox-box-shadow);
    transition: box-shadow .25s ease-in-out, transform .25s ease-in-out;
}

.logoBox:hover {
    transform: scale(1.03);
    box-shadow: var(--bg-shadowbox-box-shadow-diffused);
}

.logoBox:active {
    box-shadow: var(--bg-shadowbox-box-shadow);
    transform: scale(.97);
}

.logo {
    height: 500px;
    width: 500px;
    object-fit: cover;
    object-position: center;
}

.ticker {
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    display: block;
    border-radius: 15px;
    width: 500px;
    height: fit-content;
    padding: .5em;
    line-height: 20px;
    font-size: 2em;
    color: var(--brand-bg-txt-primary);
    background: var(--brand-bg-gradient-txt-tertiary-accent2-medium);
    box-shadow: var(--bg-shadowbox-box-shadow)
}

.ticker span {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 30s linear infinite;
}

.link-block-4 {
    display: block;
    text-align: center;
    margin-top: 20px;
    width: 100%;
}

.nav-link {
    text-decoration: none;
    color: var(--text-primary);
    padding: .4rem;
}

.imgButton {
    border-radius: 50%;
    flex: auto;
    align-content: center;
    text-transform: uppercase;
    text-decoration: none;
    width: 300px;
    letter-spacing: 2px;
    margin: 5px 3px;
    padding: 5px;
    background-color: var(--brand-accent1-light);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
    font-size: 11px;
    transition: all 0.3s ease 0s;
    cursor: pointer;
}

.portfolioImg {
    width: 300px;
    height: 200px;
}

.imgButton:hover {
    background-color:var(--brand-accent2-medium);
    box-shadow: 0 15px 20px var(--brand-accent2-medium);
    color: #fff;
    transform: translateY(-5px);
}

@media (max-width: 992px) {
    .brandWrapper div, .brandWrapper button {
        margin: 5px 0;
    }
    .topHeader, .bottomHeader {
        font-size: 8vh;
    }
    .logo {
        height: 400px;
        width: 400px;
    }

    .ticker {
        width: 400px;
    }
}

@media (max-width: 768px) {
    .brandWrapper div, .brandWrapper button {
        margin: 4px 0;
    }

    .topHeader, .bottomHeader {
        font-size: 6vh;
    }

    .logo {
        height: 300px;
        width: 300px;
    }

    .ticker {
        width: 300px;
    }
}

@media (max-width: 576px) {
    .brandWrapper {
        flex-direction: column;
        max-width: 100vw;
    }

    .brandWrapper div, .brandWrapper button {
        margin: 2px 0;
    }

    .topHeader, .bottomHeader {
        font-size: 8vh;
    }

    .logo {
        height: 300px;
        width: 300px;
    }

    .ticker {
        width: 100vw;
        border-radius: 0;
        overflow-x: clip;
    }

    .ticker span {
        display: inline-block;
        padding-left: 100%;
        animation: marquee 40s linear infinite;
    }

    .lookArrow {
      margin-bottom: 12px;
    }
}