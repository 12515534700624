.shrink {
    transition: height, width ease-in-out 1s;
}

.grow {
    transform: translateY(-30px);
    height: 500px;
    width: 500px;
}

@media (max-width: 992px) {
    .grow {
        height: 400px;
        width: 400px;
    }
}

@media (max-width: 768px) {
    .grow {
        height: 300px;
        width: 300px;
    }
}

@media (max-width: 576px) {
    .grow {
        height: 300px;
        width: 300px;
    }

}