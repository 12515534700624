.modalImages {
    object-fit: cover;
    object-position: center;
    height: 50vh;
    overflow: hidden;
}

.modal-links {
    margin-top: 3em;
    margin-bottom: 3em;
    height: fit-content;
}

.modal-text {
    color: var(--text-tertiary);
}

.imgBlock img.modalFooter {
    width: 100px;
    height: auto;
}

.my-modal .btn-close {
    font-size: 2em;
}

.my-carousel .carousel-control-next-icon,
.my-carousel .carousel-control-prev-icon {
    border-radius: 50%;
    background-color: var(--brand-alpha-bg-txt-secondary) !important;
}

.modal-link {
    margin: 2em;
    text-decoration: none;
}

@media (max-width: 992px) {
    .my-modal .modal-dialog {
        max-width: 80%;
        margin: 30px auto;
    }
}

@media (max-width: 768px) {
    .my-modal .modal-dialog {
        max-width: 90%;
    }
}

@media (max-width: 568px) {
    .my-modal .modal-dialog {
        max-width: 95%;
        z-index: 999999999999999;
    }

    .modal-link {
        margin: .5em;
    }
}