.imageGroupBox {
    overflow: auto;
    white-space: nowrap;
    height: 100%;
    display: flex;
    flex-direction: row;
    border-radius: 20px;
    background-color: transparent;
}

.imageGroupBox::-webkit-scrollbar {
    display: none; /* For Chrome, Safari and Opera */
}

.singleImgBox {
    height: 740px;
    width: 250px;
    overflow: hidden;
    transition:
            height 1s ease-in-out,
            width 1s ease-in-out,
            background 2s ease-in-out,
            transform 0.3s ease 0s,
            box-shadow 1s ease-in-out;
    text-align: center;
    border-radius: 15px;
    background: var(--brand-bg-gradient-primary);
    box-shadow: var(--bg-shadowbox-box-shadow);
}

.singleImgBox:hover {
    height: 740px;
    width: 950px;
    background: var(--brand-bg-gradient-txt-tertiary-accent2-medium);
    color: var(--brand-bg-txt-primary);
    box-shadow: var(--bg-shadowbox-box-shadow-diffused);
    transform: var(--primary-hover-transform);
}

.singleImgBox:active {
    box-shadow: var(--bg-shadowbox-box-shadow);
    transform: var(--primary-active-transform);
}

.projectTitle {
    font-weight: bold;
    margin-top: .25em;
}

.singleImgBox:hover .projectTitle {
    color: var(--brand-bg-txt-primary);
}

.carouselImg {
    object-fit: cover;
    height: 90%;
    width: 900px;
}

.carouselImg:hover {
    box-shadow: var(--bg-shadowbox-box-shadow);
}

.linkWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 992px) {
    .imageGroupBox {
        height: auto; /* Let the height expand automatically */
        width: 100%; /* Give it the full width available */
        display: flex;
        flex-direction: row; /* Display children in a vertical column */
        flex-wrap: wrap;
        align-items: center; /* Center images horizontally */
        justify-content: center;
    }

    .projectTitle {
        font-size: 20pt;
    }

    .singleImgBox {
        width: fit-content;
        height: fit-content;
        margin: 1em;
        padding-bottom: 1em;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        box-shadow: var(--bg-shadowbox-box-shadow);
    }

    .singleImgBox:hover {
        width: auto;
        height: auto;
    }

    .carouselImg {
        width: 300px;
        object-fit: contain;
        border-radius: 0;
    }
}

@media (max-width: 768px) {

}

@media (max-width: 576px) {

}