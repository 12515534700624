.subCardHeader {
    font-size: 1.25rem;
}


.subCardText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cardBody {
    word-wrap: break-word;
}

.cardHeaderBox {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.cardListItem {
    list-style: outside url("../../../../assets/img/site/logos/logo-list-item.png");
    margin-bottom: 10px;
}

.text-muted {
    color: #777;
}

/* Media Queries */
@media (max-width: 1024px) {
    .subCard-grid-3 {
        width: calc((100% - 1.5em) / 2);
    }
}

@media (max-width: 768px) {
    .subCard-grid-3 {
        width: 100%;
    }

    .subCardHeader {
        font-size: 1rem;
    }
    .cardListItem {
        margin-bottom: 5px;
    }
}

@media (max-width: 576px) {
    .cardListItem {
        list-style: outside disc;
    }
}