a {
    text-decoration: none;
}

.portfolioBox {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: fit-content;
    width: 100%;
    color: var(--text-primary);

    transition: background-color 0.4s ease-in, color 0.4s ease-in;
}

.portfolioContentWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    width: 100%;
    height: fit-content;
    overflow: hidden;
}

.portfolioContent {
    font-size: 2vh;
    padding: 2em;
    margin: 4em;
    overflow: hidden;
}

@media (max-width: 992px) {
}

@media (max-width: 768px) {
}

@media (max-width: 576px) {
    .portfolioContent {
        font-size: 16px;
        padding: 1em;
        margin: 2em;
        border-radius: 10px;
        width: fit-content;
    }

}