/*Experience.css*/

.experienceContainer {
    background-color: var(--brand-bg-txt-primary);
}

.pageCap {
    width: 100vw;
    height: 150px;
    background-size: cover;
}

.pageTitle {
    margin-top: 2em;
    margin-bottom: 2em;
    color: var(--brand-accent2-medium);
    font-size: 42px;
    font-weight: bold;
    text-align: start;
}

.nameSpan {
    font-family: 'Megrim', monospace;
}

.container-fluid {
    margin-top: 20px;
}

/* Media Queries */
@media (max-width: 992px) {
    .pageTitle {
        font-size: 1.2rem;
    }

}

@media (max-width: 768px) {
    .pageTitle {
        font-size: 1rem;
    }

}

@media (max-width: 576px) {
    .pageTitle {
    }

}