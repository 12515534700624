/*Authored by Tyler Smalley*/

:root {
    scroll-behavior: smooth;

    /* Gradient variables */
    --brand-bg-gradient-primary: linear-gradient(45deg, var(--brand-primary-blend), var(--brand-bg-txt-primary), var(--brand-primary-blend));
    --brand-bg-gradient-secondary-primary: linear-gradient(45deg, var(--brand-bg-txt-secondary), var(--brand-bg-txt-primary), var(--brand-bg-txt-secondary));
    --brand-bg-gradient-secondary-accent1-medium: linear-gradient(45deg, var(--brand-bg-txt-secondary), var(--brand-accent1-medium), var(--brand-bg-txt-secondary));
    --brand-bg-gradient-txt-secondary-accent1-dark-medium: linear-gradient(45deg, var(--brand-bg-txt-secondary), var(--brand-accent1-dark-medium), var(--brand-bg-txt-secondary));
    --brand-bg-gradient-accent1-dark-medium-accent1-medium: linear-gradient(45deg, var(--brand-accent1-dark-medium), var(--brand-accent1-medium), var(--brand-accent1-dark-medium));
    --brand-bg-gradient-accent1-medium-accent1-medium-light: linear-gradient(45deg, var(--brand-accent1-medium), var(--brand-accent1-medium-light), var(--brand-accent1-medium));
    --brand-bg-gradient-accent1-medium-light-accent1-light: linear-gradient(45deg, var(--brand-accent1-medium-light), var(--brand-accent1-light), var(--brand-accent1-medium-light));
    --brand-bg-gradient-accent1-medium-accent1-light: linear-gradient(45deg, var(--brand-accent1-medium), var(--brand-accent1-light), var(--brand-accent1-medium));
    --brand-bg-gradient-accent1-light-txt-tertiary: linear-gradient(45deg, var(--brand-bg-txt-tertiary), var(--brand-accent1-light), var(--brand-bg-txt-tertiary));
    --brand-bg-gradient-txt-tertiary-accent2-dark-medium: linear-gradient(45deg, var(--brand-bg-txt-tertiary), var(--brand-accent2-dark-medium), var(--brand-bg-txt-tertiary));
    --brand-bg-gradient-txt-tertiary-accent2-medium: linear-gradient(45deg, var(--brand-bg-txt-tertiary), var(--brand-accent2-medium), var(--brand-bg-txt-tertiary));
    --brand-bg-gradient-accent2-dark-medium-accent2-medium: linear-gradient(45deg, var(--brand-accent2-dark-medium), var(--brand-accent2-medium), var(--brand-accent2-dark-medium));
    --brand-bg-gradient-accent2-medium-accent2-medium-light: linear-gradient(45deg, var(--brand-accent2-medium), var(--brand-accent2-medium-light), var(--brand-accent2-medium));
    --brand-bg-gradient-accent2-medium-accent2-light: linear-gradient(45deg, var(--brand-accent2-medium), var(--brand-accent2-light), var(--brand-accent2-medium));
    --brand-bg-gradient-accent2-txt-primary: linear-gradient(45deg, var(--brand-accent2-medium), var(--brand-bg-txt-primary), var(--brand-accent2-medium));

    /* RGBA Gradient variables */
    --brand-alpha-bg-gradient-primary: linear-gradient(45deg, rgba(236, 227, 217, 0.75), var(--brand-alpha-bg-txt-primary), rgba(236, 227, 217, 0.75));
    --brand-alpha-bg-gradient-secondary-primary: linear-gradient(90deg, var(--brand-alpha-bg-txt-secondary), var(--brand-alpha-bg-txt-primary), var(--brand-alpha-bg-txt-secondary));
    --brand-alpha-bg-gradient-secondary-accent1-medium: linear-gradient(45deg, var(--brand-alpha-bg-txt-secondary), var(--brand-alpha-accent1-medium), var(--brand-alpha-bg-txt-secondary));
    --brand-alpha-bg-gradient-txt-secondary-accent1-dark-medium: linear-gradient(45deg, var(--brand-alpha-bg-txt-secondary), var(--brand-alpha-accent1-dark-medium), var(--brand-alpha-bg-txt-secondary));
    --brand-alpha-bg-gradient-accent1-dark-medium-accent1-medium: linear-gradient(45deg, var(--brand-alpha-accent1-dark-medium), var(--brand-alpha-accent1-medium), var(--brand-alpha-accent1-dark-medium));
    --brand-alpha-bg-gradient-accent1-medium-accent1-medium-light: linear-gradient(45deg, var(--brand-alpha-accent1-medium), var(--brand-alpha-accent1-medium-light), var(--brand-alpha-accent1-medium));
    --brand-alpha-bg-gradient-accent1-medium-light-accent1-light: linear-gradient(45deg, var(--brand-alpha-accent1-medium-light), var(--brand-alpha-accent1-light), var(--brand-alpha-accent1-medium-light));
    --brand-alpha-bg-gradient-accent1-medium-accent1-light: linear-gradient(45deg, var(--brand-alpha-accent1-medium), var(--brand-alpha-accent1-light), var(--brand-alpha-accent1-medium));
    --brand-alpha-bg-gradient-accent1-light-txt-tertiary: linear-gradient(90deg, var(--brand-alpha-bg-txt-tertiary), var(--brand-alpha-accent1-light), var(--brand-alpha-bg-txt-tertiary));
    --brand-alpha-bg-gradient-txt-tertiary-accent2-dark-medium: linear-gradient(45deg, var(--brand-alpha-bg-txt-tertiary), var(--brand-alpha-accent2-dark-medium), var(--brand-alpha-bg-txt-tertiary));
    --brand-alpha-bg-gradient-txt-tertiary-accent2-medium: linear-gradient(45deg, var(--brand-alpha-bg-txt-tertiary), var(--brand-alpha-accent2-medium), var(--brand-alpha-bg-txt-tertiary));
    --brand-alpha-bg-gradient-accent2-dark-medium-accent2-medium: linear-gradient(45deg, var(--brand-alpha-accent2-dark-medium), var(--brand-alpha-accent2-medium), var(--brand-alpha-accent2-dark-medium));
    --brand-alpha-bg-gradient-accent2-medium-accent2-medium-light: linear-gradient(45deg, var(--brand-alpha-accent2-medium), var(--brand-alpha-accent2-medium-light), var(--brand-alpha-accent2-medium));
    --brand-alpha-bg-gradient-accent2-medium-accent2-light: linear-gradient(45deg, var(--brand-alpha-accent2-medium), var(--brand-alpha-accent2-light), var(--brand-alpha-accent2-medium));
    --brand-alpha-bg-gradient-accent2-txt-primary: linear-gradient(45deg, var(--brand-alpha-accent2-medium), var(--brand-alpha-bg-txt-primary), var(--brand-alpha-accent2-medium));

    /*text*/
    --text-primary: var(--brand-bg-txt-primary);
    --text-secondary: var(--brand-accent1-medium);
    --text-tertiary: var(--brand-accent2-medium);

    /*Shadowboxes*/
    --bg-shadowbox-color: rgba(84, 45, 37, 0.7);
    --primary-active-box-shadow: var(--bg-shadowbox-box-shadow-indent);
    --bg-shadowbox-box-shadow: -5px 5px 5px #0b0b0b, 5px -5px 5px transparent;
    --bg-shadowbox-box-shadow-diffused: -15px 15px 15px 2px rgba(0, 0, 0, 0.6);
    --bg-shadowbox-box-shadow-indent: inset -5px 5px 5px #0b0b0b, inset 5px -5px 5px transparent;
    --bg-shadowbox-box-shadow-indent-diffused: inset -15px 15px 15px 2px rgba(0, 0, 0, 0.6);

    /*buttons*/
    --primary-hover-bg-color: var(--brand-accent2-medium);
    --primary-hover-bg-gradient: var(--brand-bg-gradient-txt-tertiary-accent2-medium);
    --primary-hover-color: var(--brand-bg-txt-primary);
    --primary-hover-transform: scale(1.03);
    --primary-active-transform: scale(.97);

    /*NAV-MENU*/

    /*hamburger menu*/
    --burger-bar-color: var(--brand-bg-txt-primary);
}

[data-theme='woodland-hues'] {
    /*main colors*/
    --brand-bg-txt-primary: #F7F5EE;
    --brand-bg-txt-secondary: #3F2722;
    --brand-bg-txt-tertiary: #0B3943;

    /*accent colors*/
    --brand-primary-blend: #ECE3D9;
    --brand-accent1-dark-medium: #573128;
    --brand-accent1-medium: #704234;
    --brand-accent1-medium-light: #7F5544;
    --brand-accent1-light: #9A7964;
    --brand-accent2-dark-medium: #0D525E;
    --brand-accent2-medium: #096B7B;
    --brand-accent2-medium-light: #538F9E;
    --brand-accent2-light: #9DD8D6;

    /*RGB variations with alpha*/

    /*main colors*/
    --brand-alpha-bg-txt-primary: rgba(247, 245, 238, 0.80);
    --brand-alpha-bg-txt-secondary: rgba(63, 39, 34, 0.80);
    --brand-alpha-bg-txt-tertiary: rgba(11, 57, 67, 0.75);

    /*accent colors*/
    --brand-alpha-accent1-dark-medium: rgba(87, 49, 40, 0.80);
    --brand-alpha-accent1-medium: rgba(112, 66, 52, 0.80);
    --brand-alpha-accent1-medium-light: rgba(127, 85, 68, 0.80);
    --brand-alpha-accent1-light: rgba(154, 121, 100, 0.75);
    --brand-alpha-accent2-dark-medium: rgba(13, 82, 94, 0.80);
    --brand-alpha-accent2-medium: rgba(9, 107, 123, 0.80);
    --brand-alpha-accent2-medium-light: rgba(83, 143, 158, 0.80);
    --brand-alpha-accent2-light: rgba(157, 216, 214, 0.80);
}

[data-theme='midnight-velvet'] {
    /* Main colors */
    --brand-bg-txt-primary: #1A1A1A; /* Charcoal black */
    --brand-bg-txt-secondary: #2C2C2C; /* Deep gray */
    --brand-bg-txt-tertiary: #4A4A4A; /* Slate gray */

    /* Accent colors */
    --brand-primary-blend: #3B3B3B; /* Dark gray */
    --brand-accent1-dark-medium: #1E1E1E; /* Almost black */
    --brand-accent1-medium: #4A4A4A; /* Medium slate gray */
    --brand-accent1-medium-light: #6E6E6E; /* Light slate gray */
    --brand-accent1-light: #8C8C8C; /* Pale gray */
    --brand-accent2-dark-medium: #7F8C8D; /* Dark muted teal */
    --brand-accent2-medium: #4C6A6F; /* Medium teal */
    --brand-accent2-medium-light: #9BB2B0; /* Light teal */
    --brand-accent2-light: #B7C2C1; /* Very light teal */

    /* RGB variations with alpha */

    /* Main colors */
    --brand-alpha-bg-txt-primary: rgba(26, 26, 26, 0.80);
    --brand-alpha-bg-txt-secondary: rgba(44, 44, 44, 0.80);
    --brand-alpha-bg-txt-tertiary: rgba(74, 74, 74, 0.75);

    /* Accent colors */
    --brand-alpha-accent1-dark-medium: rgba(30, 30, 30, 0.80);
    --brand-alpha-accent1-medium: rgba(74, 74, 74, 0.80);
    --brand-alpha-accent1-medium-light: rgba(110, 110, 110, 0.80);
    --brand-alpha-accent1-light: rgba(140, 140, 140, 0.75);
    --brand-alpha-accent2-dark-medium: rgba(127, 140, 141, 0.80);
    --brand-alpha-accent2-medium: rgba(76, 106, 111, 0.80);
    --brand-alpha-accent2-medium-light: rgba(155, 178, 176, 0.80);
    --brand-alpha-accent2-light: rgba(183, 194, 193, 0.80);
}

[data-theme='summer-vibes'] {
    /* Main colors */
    --brand-bg-txt-primary: #F7F7F2; /* Warm summer beige */
    --brand-bg-txt-secondary: #F4C300; /* Bold, sunny orange */
    --brand-bg-txt-tertiary: #1E4A8F; /* Deep blue sky */

    /* Accent colors */
    --brand-primary-blend: #F0E8C9; /* Soft cream */
    --brand-accent1-dark-medium: #00274D; /* Navy blue */
    --brand-accent1-medium: #00509E; /* Ocean blue */
    --brand-accent1-medium-light: #0074D9; /* Sky blue */
    --brand-accent1-light: #9ECCE6; /* Light blue sky */
    --brand-accent2-dark-medium: #005792; /* Bold blue */
    --brand-accent2-medium: #4F9D9D; /* Teal */
    --brand-accent2-medium-light: #7DCE82; /* Fresh green */
    --brand-accent2-light: #B4E1E8; /* Pale blue */

    /* RGB variations with alpha */

    /* Main colors */
    --brand-alpha-bg-txt-primary: rgba(247, 247, 242, 0.80);
    --brand-alpha-bg-txt-secondary: rgba(255, 191, 0, 0.80);
    --brand-alpha-bg-txt-tertiary: rgba(30, 74, 143, 0.75);

    /* Accent colors */
    --brand-alpha-accent1-dark-medium: rgba(0, 39, 77, 0.80);
    --brand-alpha-accent1-medium: rgba(0, 80, 158, 0.80);
    --brand-alpha-accent1-medium-light: rgba(0, 116, 217, 0.80);
    --brand-alpha-accent1-light: rgba(158, 204, 230, 0.75);
    --brand-alpha-accent2-dark-medium: rgba(0, 87, 146, 0.80);
    --brand-alpha-accent2-medium: rgba(79, 157, 157, 0.80);
    --brand-alpha-accent2-medium-light: rgba(125, 206, 130, 0.80);
    --brand-alpha-accent2-light: rgba(180, 225, 232, 0.80);
}

[data-theme='autumn-reverie'] {
    /* Main colors */
    --brand-bg-txt-primary: #F4E8D7; /* Soft autumn cream */
    --brand-bg-txt-secondary: #7D4F3F; /* Warm chestnut brown */
    --brand-bg-txt-tertiary: #6D3F2D; /* Deep oak brown */

    /* Accent colors */
    --brand-primary-blend: #F6D6C8; /* Light pumpkin orange */
    --brand-accent1-dark-medium: #5C3F2A; /* Rustic sienna */
    --brand-accent1-medium: #8C5C3F; /* Harvest gold */
    --brand-accent1-medium-light: #B87A53; /* Golden leaf */
    --brand-accent1-light: #D1A96D; /* Spiced caramel */
    --brand-accent2-dark-medium: #4A2C1F; /* Autumnal brown */
    --brand-accent2-medium: #6F4F28; /* Maple syrup */
    --brand-accent2-medium-light: #8C6E4E; /* Burnt umber */
    --brand-accent2-light: #B9A17F; /* Autumn mist */

    /* RGB variations with alpha */

    /* Main colors */
    --brand-alpha-bg-txt-primary: rgba(244, 232, 215, 0.80);
    --brand-alpha-bg-txt-secondary: rgba(125, 79, 63, 0.80);
    --brand-alpha-bg-txt-tertiary: rgba(109, 63, 45, 0.75);

    /* Accent colors */
    --brand-alpha-accent1-dark-medium: rgba(92, 63, 42, 0.80);
    --brand-alpha-accent1-medium: rgba(140, 92, 63, 0.80);
    --brand-alpha-accent1-medium-light: rgba(184, 122, 83, 0.80);
    --brand-alpha-accent1-light: rgba(209, 169, 109, 0.75);
    --brand-alpha-accent2-dark-medium: rgba(74, 44, 31, 0.80);
    --brand-alpha-accent2-medium: rgba(111, 79, 40, 0.80);
    --brand-alpha-accent2-medium-light: rgba(140, 110, 78, 0.80);
    --brand-alpha-accent2-light: rgba(185, 161, 127, 0.80);
}

[data-theme='winter-chill'] {
    /* Main colors */
    --brand-bg-txt-primary: #F0F4F8; /* Frosty white */
    --brand-bg-txt-secondary: #BCC8D2; /* Icy blue-gray */
    --brand-bg-txt-tertiary: #8A9AB8; /* Cool steel blue */

    /* Accent colors */
    --brand-primary-blend: #DCE6F2; /* Soft snow blue */
    --brand-accent1-dark-medium: #4A5D81; /* Winter night blue */
    --brand-accent1-medium: #6D7F9A; /* Glacier gray-blue */
    --brand-accent1-medium-light: #8B9DB0; /* Icy slate */
    --brand-accent1-light: #B0C2D6; /* Frosted sky */
    --brand-accent2-dark-medium: #2E3A52; /* Deep midnight blue */
    --brand-accent2-medium: #4C5B76; /* Snowy dusk blue */
    --brand-accent2-medium-light: #6D7F9A; /* Soft winter blue */
    --brand-accent2-light: #9FB3C9; /* Light wintry mist */

    /* RGB variations with alpha */

    /* Main colors */
    --brand-alpha-bg-txt-primary: rgba(240, 244, 248, 0.80);
    --brand-alpha-bg-txt-secondary: rgba(188, 200, 210, 0.80);
    --brand-alpha-bg-txt-tertiary: rgba(138, 154, 184, 0.75);

    /* Accent colors */
    --brand-alpha-accent1-dark-medium: rgba(74, 93, 129, 0.80);
    --brand-alpha-accent1-medium: rgba(109, 127, 154, 0.80);
    --brand-alpha-accent1-medium-light: rgba(139, 157, 176, 0.80);
    --brand-alpha-accent1-light: rgba(176, 194, 214, 0.75);
    --brand-alpha-accent2-dark-medium: rgba(46, 58, 82, 0.80);
    --brand-alpha-accent2-medium: rgba(76, 91, 118, 0.80);
    --brand-alpha-accent2-medium-light: rgba(109, 127, 154, 0.80);
    --brand-alpha-accent2-light: rgba(159, 179, 201, 0.80);
}

[data-theme='spring-bloom'] {
    /*main colors*/
    --brand-bg-txt-primary: #FAF8EF; /* A soft cream */
    --brand-bg-txt-secondary: #FFD1DC; /* Pastel pink */
    --brand-bg-txt-tertiary: #A7C7E7; /* Pastel sky blue */

    /*accent colors*/
    --brand-primary-blend: #FCE6C9; /* Light peach */
    --brand-accent1-dark-medium: #FFB3BA; /* Soft red */
    --brand-accent1-medium: #FFDAC1; /* Light coral */
    --brand-accent1-medium-light: #FFF5BA; /* Pastel yellow */
    --brand-accent1-light: #FFFFD1; /* Soft butter yellow */
    --brand-accent2-dark-medium: #A1E3D8; /* Soft teal */
    --brand-accent2-medium: #B5EAD7; /* Pastel mint */
    --brand-accent2-medium-light: #C7CEEA; /* Light lavender */
    --brand-accent2-light: #E2F0CB; /* Soft green */

    /*RGB variations with alpha*/

    /*main colors*/
    --brand-alpha-bg-txt-primary: rgba(250, 248, 239, 0.80); /* Cream with transparency */
    --brand-alpha-bg-txt-secondary: rgba(255, 209, 220, 0.80); /* Pink with transparency */
    --brand-alpha-bg-txt-tertiary: rgba(167, 199, 231, 0.75); /* Blue with transparency */

    /*accent colors*/
    --brand-alpha-accent1-dark-medium: rgba(255, 179, 186, 0.80); /* Soft red with transparency */
    --brand-alpha-accent1-medium: rgba(255, 218, 193, 0.80); /* Coral with transparency */
    --brand-alpha-accent1-medium-light: rgba(255, 245, 186, 0.80); /* Yellow with transparency */
    --brand-alpha-accent1-light: rgba(255, 255, 209, 0.75); /* Butter yellow with transparency */
    --brand-alpha-accent2-dark-medium: rgba(161, 227, 216, 0.80); /* Teal with transparency */
    --brand-alpha-accent2-medium: rgba(181, 234, 215, 0.80); /* Mint with transparency */
    --brand-alpha-accent2-medium-light: rgba(199, 206, 234, 0.80); /* Lavender with transparency */
    --brand-alpha-accent2-light: rgba(226, 240, 203, 0.80); /* Soft green with transparency */
}