.grid-container {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-bg-wrapper {
    position: absolute;  /* make absolute to overlay on grid */
    width: 100%;
    height: 800px;
    max-width: 1090px; /* match with .grid max-width */
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-container {
    height: 50%;
    width: 70%;
    line-height: 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: justify;
}

.text-container button {
    width: 50%;
    margin-top: auto;
}

.grid {
    display: grid;
    grid-template-rows: repeat(4, 200px);
    grid-template-columns: repeat(3, minmax(100px, 1fr));
    grid-gap: 3px;
    width: 100%;
    max-width: 1100px;
    z-index: 2;
    overflow: hidden;
}

.topLeft {
    width: 100%;
    height: 300%;
    grid-row: 1 / 1;
    grid-column: 1 / 1;
    transition: transform 0.3s ease-out, width 0.3s ease-out, height 0.3s ease-out, opacity 0.1s ease-out;
}

.fullGrid {
    width: 100% !important;
    height: 100% !important;
    grid-row: 1 / 5 !important;
    grid-column: 1 / 4 !important;
    z-index: 101 !important;
}

.topRight {
    width: 200%;
    height: 200%;
    grid-row: 1 / 1;
    grid-column: 2 / 2;
    transition: transform 0.3s ease-out, width 0.3s ease-out, height 0.3s ease-out, opacity 0.1s ease-out;
}

.bottomRight {
    width: 100%;
    height: 200%;
    grid-row: 3 / 3;
    grid-column: 3 / 3;
    transition: transform 0.3s ease-out, width 0.3s ease-out, height 0.3s ease-out, opacity 0.1s ease-out;
}

.bottomLeft {
    width: 200%;
    height: 100%;
    grid-row: 4 / 4;
    grid-column: 1 / 1;
    transition: transform 0.3s ease-out, width 0.3s ease-out, height 0.3s ease-out, opacity 0.1s ease-out;
}

.center {
    width: 100%;
    height: 97%;
    grid-row: 3 / 3;
    grid-column: 2 / 3;
    z-index: 100;
    transition: transform 0.3s ease-out, width 0.5s ease-out, height 0.5s ease-out, opacity 0.1s ease-out, box-shadow 0.3s ease-out;
}

.activateButton {
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.grid > .item {
    cursor: pointer;
    object-fit: cover;
    object-position: center;
}

.bottomLeftEffect {
    height: 203%;
    transform: translateY(-101%);
}

.topLeftEffect {
    transform: translateX(101%);
}

.topRightEffect {
    transform: translateY(105%);
}

.bottomRightEffect {
    transform: translateX(-101%);
}

.topLeftActive {
    height: 200%;
}

.topRightHoverActive, .bottomLeftHoverActive {
    width: 100%;
}

.topRightHoverActive {
    grid-column: 3/3;
}

.bottomRightHoverActive {
    height: 100%;
    grid-row: 4/4;
}

.grid > .item:hover.topLeft {
    width: 200%;
    z-index: 1;
}

.grid > .item:hover.topRight {
    height: 300%;
    z-index: 1;
}

.grid > .item:hover.bottomRight {
    transform: translateX(-50%);
    width: 200%;
    z-index: 1;
}

.grid > .item:hover.bottomLeft {
    transform: translateY(-50%);
    height: 200%;
    z-index: 1;
}

.grid > .item:hover.center {
    box-shadow: var(--bg-shadowbox-box-shadow-diffused);
    transform: scale(1.7);
}

.grid > .item:active.center {
    box-shadow: var(--bg-shadowbox-box-shadow);
    transform: scale(1.3);
}

.grid > .item:hover.center .activateButton {
    opacity: 1;
}

.noTransform:hover {
    transform: none !important;
}

.disable-pointer-events {
    pointer-events: none;
}

/* Media Queries */
@media (max-width: 1025px) {
    .grid-container {
        width: 100%;
    }
}

@media (max-width: 769px) {

}

@media (max-width: 576px) {
    .text-container {
        line-height: 1.75em;
        width: fit-content;
        height: 90%;
        overflow-y: auto;
    }

    .text-container button {
        width: 75%;
    }

    .grid {
        grid-gap: 2px;
    }
}

/*ANIMATIONS*/
.bottomLeftAnimate {
    animation-name: animation-bottomLeft;
    animation-duration: 1.25s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    z-index: 3 !important;
}

.centerAnimate {
    animation-name: animation-center;
    animation-duration: 1.25s;
    animation-delay: 0.5s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    z-index: 4 !important;
}

.bottomRightAnimate {
    animation-name: animation-bottomRight;
    animation-duration: 1.25s;
    animation-delay: 1s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    z-index: 5 !important;
}

.topRightAnimate {
    animation-name: animation-topRight;
    animation-duration: 1.25s;
    animation-delay: 1.5s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    z-index: 6 !important;
}

.topLeftAnimate {
    animation-name: animation-topLeft;
    animation-duration: 1.25s;
    animation-delay: 2s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    z-index: 7 !important;
}

.topLeftAnimateReturn {
    animation-name: animation-topLeft-return;
    animation-duration: 1.5s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

.topRightAnimateReturn {
    animation-name: animation-topRight-return;
    animation-duration: 1.5s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

.bottomRightAnimateReturn {
    animation-name: animation-bottomLeft-return;
    animation-duration: 1.5s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

.centerAnimateReturn {
    animation-name: animation-center-return;
    animation-duration: 1.5s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

.bottomLeftAnimateReturn {
    animation-name: animation-bottomRight-return;
    animation-duration: 1.5s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

/* 1 - bottomLeft */
@keyframes animation-bottomLeft {
    0% {
        box-shadow: var(--bg-shadowbox-box-shadow-indent);
        opacity: 1;
    }
    40% {
        height: 0;
        opacity: 1;
    }
    60% {
        opacity: 0;
    }
    100% {
        height: 0;
        opacity: 0;
        grid-row: 1 / 1;
        grid-column: 1 / 1;
    }
}

/* 2 - center */
@keyframes animation-center {
    0% {
        box-shadow: var(--bg-shadowbox-box-shadow-indent);
        opacity: 1;
    }
    55% {
        transform: translateX(100%);
        width: 100%;
        opacity: 1;
    }
    60% {
        width: 0;
        opacity: 0;
    }
    100% {
        width: 0;
        opacity: 0;
        grid-row: 2 / 2;
        grid-column: 3 / 3;
    }
}

/* 3 - bottomRight */
@keyframes animation-bottomRight {
    0% {
        box-shadow: var(--bg-shadowbox-box-shadow-indent);
        opacity: 1;
    }
    40% {
        transform: translateY(-100%);
        opacity: 1;
    }
    60% {
        opacity: 0;
    }
    100% {
        height: 0;
        opacity: 0;
        grid-row: 1 / 1;
    }
}

/* 4 - topRight */
@keyframes animation-topRight {
    0% {
        box-shadow: var(--bg-shadowbox-box-shadow-indent);
        opacity: 1;
        z-index: 2;
    }
    50% {
        transform: translateX(-100%);
        opacity: 1;
    }
    55% {
        opacity: 0;
    }
    100% {
        width: 0;
        opacity: 0;
        visibility: hidden;
    }
}

/* 5 - topLeft */
@keyframes animation-topLeft {
    0% {
        box-shadow: var(--bg-shadowbox-box-shadow-indent);
        opacity: 1;
    }
    40% {
        height: 100%;
        opacity: 1;
    }
    55% {
        height: 0;
        opacity: 0;
    }
    100% {
        height: 0;
        opacity: 0;
    }
}

/* 1 - topLeft */
@keyframes animation-topLeft-return {
    0% {
        height: 0;
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

/* 2 - topRight */
@keyframes animation-topRight-return {
    0% {
        width: 0;
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* 3 - bottomRight */
@keyframes animation-bottomRight-return {
    0% {
        height: 0;
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* 4 - center */
@keyframes animation-center-return {
    0% {
        width: 0;
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* 5 - bottomLeft */
@keyframes animation-bottomLeft-return {
    0% {
        height: 0;
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}