.accordionGroup {
    width: 100%;
    height: fit-content;
}

.accordionWrapper {
    width: 100%;
}

.accordion {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    color: var(--brand-accent2-medium);
}

.accordion-btn {
    position: relative;
    width: 100%;
    color: var(--brand-accent2-medium);
    transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);
    cursor: pointer;
    user-select: none;
}

.accordion-btn:before, .accordion-btn:after {
    content: '';
    position: absolute;
    transition: inherit;
    z-index: -1;
}

.accordion-btn:hover {
    color: var(--brand-bg-txt-primary);
    transition-delay: .5s;
}

.accordion-btn:hover:before {
    transition-delay: 0s;
}

.accordion-btn:hover:after {
    background: var(--brand-bg-gradient-txt-tertiary-accent2-medium);
    transition-delay: .35s;
}

.from-center:before {
    top: 0;
    left: 50%;
    height: 100%;
    width: 0;
    border: 1px solid var(--brand-accent2-medium);
    border-left: 0;
    border-right: 0;
}

.from-center:after {
    bottom: 0;
    left: 0;
    height: 0;
    width: 100%;
    background: var(--brand-bg-gradient-txt-tertiary-accent2-medium);
}

.from-center:hover:before {
    left: 0;
    width: 100%;
}

.from-center:hover:after {
    top: 0;
    height: 100%;
}

.accordion.active {
    background: var(--brand-bg-gradient-accent1-medium-accent1-light);
    color: var(--text-primary);
    height: fit-content;
}

.accordion.active > .accordion-btn {
    color: var(--brand-bg-txt-primary);
}

.accordion-header {
    font-family: 'Megrim', fantasy;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 150px;
    text-align: center;
    font-size: 5vh;
    font-weight: bold;
}

.accordion.active .accordion-header:hover {
    color: var(--brand-accent2-medium);
}

.accordion-content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../../assets/img/site/backgrounds/starfield1.webp');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 0;
    overflow-y: auto;
}

.accordion.active .accordion-content-wrapper {
    animation: openAccordion 0.5s forwards;
}

.accordion:not(.active) .accordion-content-wrapper {
    animation: closeAccordion 0.5s forwards;
}

.accordion-content {
    color: var(--text-primary);
    width: 75%;
    font-size: 2vh;
    opacity: 0;
    border-radius: 10px;
}

.accordionTableItem {
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--brand-accent2-medium);
    background: var(--brand-bg-gradient-primary);
    text-decoration: none;
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
    transition: background 0.5s ease-out, color 0.3s ease-out;
}

.accordionTableItem:hover {
    background: var(--brand-bg-gradient-txt-tertiary-accent2-medium);
    color: var(--brand-bg-txt-primary);
    transition: background 0.1s ease-in, color 0.3s ease-in;
}

.accordionContentBox {
    display: flex;
    overflow-y: auto;
    justify-content: center;
    align-items: center;
    padding-top: 1em;
    padding-bottom: 1em;
}

.accordionContentBox::-webkit-scrollbar {
    display: none; /* For Chrome, Safari and Opera */
}

.projectTitle {
    color: var(--brand-accent2-medium);
    font-weight: bold;
    margin-top: .25em;
}

.linkWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.accordion.active .accordion-content {
    opacity: 1;
    animation: fadeIn 0.5s ease-in-out forwards;
    animation-delay: .35s;
    animation-fill-mode: backwards;
}

.accordionContentList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.accordionContentListItem {
    flex: 1 0 auto;
    min-width: calc(33.333%);
    padding-bottom: .5em;
}

/*unused*/
.list-group-item {
    color: var(--brand-accent2-medium);
    background-color: var(--brand-bg-txt-primary);
}

.accordionBottomCap {
    width: 100%;
    height: 75px;
}

@media (max-width: 1600px) {
    .accordion-content {
        color: var(--text-primary);
        width: 100%;
        font-size: 2vh;
        opacity: 0;
        border-radius: 10px;
    }
}

@media (max-width: 992px) {
    .accordion-header {
        font-size: 4vh;
        min-height: 120px;
    }
}

@media (max-width: 768px) {
    .accordion-header {
        font-size: 3vh;
        min-height: 90px;
    }
}

@media (max-width: 576px) {

    .accordion.active:hover {
        color: var(--brand-bg-txt-primary);
    }

    .accordion.active .accordion-content-wrapper {
        padding-top: .75em;
        padding-bottom: 1.75em;
        margin-bottom: 2.5em;
        width: 100%;
        height: fit-content;
        animation: none;
    }

    .accordion:not(.active) .accordion-content-wrapper {
        height: 0;
        width: 0;
        animation: none;
    }

    .accordion-header {
        font-size: 4vh;
        min-height: 140px;
    }

    .accordion-content {
        font-size: 16px;
        border-radius: 10px;
        width: 90%;
    }

    .accordionContentBox {
        height: auto;
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
    }

}