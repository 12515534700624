.card {
    margin-bottom: 20px;
}

.subCard-grid-3 {
    width: calc((100% - 2em) / 3);
    padding: 0.5em;
    margin-bottom: 10px;
    box-shadow: 0 8px 18px -6px rgba(0, 0, 0, 0.2);
}

.subCard-row-full {
    width: 100%;
    padding: 0.5em;
    margin-bottom: 10px;
    box-shadow: 0 8px 18px -6px rgba(0, 0, 0, 0.2);
}

.subjectCard {
    margin-bottom: 20px;
    border-radius: 3px;
    width: 100% !important;
    box-shadow: 0 8px 18px -6px rgba(0, 0, 0, 0.2);
}

.subjectHeader {
    font-size: 1.5rem;
    font-weight: 700;
}

.subjectBody {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.subjectBody-row {
    padding: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.subjectBody-column {
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.cardText {
    word-wrap: break-word;
}