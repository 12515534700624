.showcaseFullWidth {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 100;
}

.showcaseWrapper {
    display: flex;
    flex-direction: column;
    max-width: 40vw;
    width: 100%;
    min-height: 400px;
}

.noImg {
    height: 500px;
}

.textBlock {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.imgBlock {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logoLink {
    transition: box-shadow .25s ease-in-out, transform .25s ease-in-out;
    box-shadow: var(--bg-shadowbox-box-shadow);
}

.logoLink:hover {
    transform: scale(1.03);
    box-shadow: var(--bg-shadowbox-box-shadow-diffused);
}

.logoLink:active {
    box-shadow: var(--bg-shadowbox-box-shadow);
    transform: scale(.97);
}

.imgBlock img {
    width: 100%;
    max-width: 200px;
    height: auto;
}

/* Media Queries */
@media (max-width: 1025px) {
    .showcaseWrapper {
        max-width: 80vw;
    }
}

@media (max-width: 768px) {
    .showcaseWrapper {
        flex-direction: row;
        max-width: 95vw;
    }

}

@media (max-width: 576px) {
    .showcaseWrapper {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 100vw;
        padding: 10px;
    }

    .textBlock, .imgBlock {
        padding: 0 1em;
        width: 90vw;
    }

    .pageTitle {
        width: fit-content;
        text-align: center;
        margin-left: 0 !important;
    }

    .textBody {

    }

}