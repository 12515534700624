/*GLOBAL ANIMATIONS*/
@keyframes jello {
    0%, 100% {
        transform: scale3d(1, 1, 1);
    }
    15% {
        transform: scale3d(1.006, 0.994, 1);
    }
    30% {
        transform: scale3d(0.994, 1.006, 1);
    }
    45% {
        transform: scale3d(1.0035, 0.9965, 1);
    }
    60% {
        transform: scale3d(0.9965, 1.0035, 1);
    }
    75% {
        transform: scale3d(1.0015, 0.9985, 1);
    }
    90% {
        transform: scale3d(0.9985, 1.0015, 1);
    }
}

@keyframes jello-vertical {
    0%, 100% {
        transform: scale3d(1, 1, 1);
    }
    15% {
        transform: scale3d(1, 0.975, 1);
    }
    30% {
        transform: scale3d(1, 1.025, 1);
    }
    45% {
        transform: scale3d(1, 0.987, 1);
    }
    60% {
        transform: scale3d(1, 1.013, 1);
    }
    75% {
        transform: scale3d(1, 0.993, 1);
    }
    90% {
        transform: scale3d(1, 1.007, 1);
    }
}

@keyframes Menu-Image-Spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes fadeOut {
    from { opacity: 1; }
    to   { opacity: 0; }
}

@keyframes fadeIn {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@keyframes marquee {
    0%   { transform: translate(0, 0); }
    100% { transform: translate(-100%, 0); }
}

@keyframes bounceDownKey {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40%, 60% {
        transform: translateY(-10px);
    }
}

@keyframes bounceUpKey {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40%, 60% {
        transform: translateY(10px);
    }
}

@keyframes pop {
    0% { transform: scale(1); }
    50% { transform: scale(1.2); }
    100% { transform: scale(1); } }


@keyframes openAccordion {
    0% {
        filter: brightness(500%);
        height: 0;
    }
    100% {
        filter: none;
        height: 85vh;
    }
}

@keyframes closeAccordion {
    0% {
        height: 85vh;
        filter: none;
    }
    100% {
        height: 0;
    }
}