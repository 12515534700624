.teaserTileGroupBox {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 20px;
    background: var(--brand-alpha-bg-gradient-txt-tertiary-accent2-dark-medium);
}

.projectTitle {
    font-size: 20pt;
}

.teaserTileBox {
    width: 300px;
    height: 400px;
    margin-bottom: 1em;
    padding-bottom: 1em;
    box-shadow: var(--bg-shadowbox-box-shadow);
}

.teaserTileImg {
    width: 300px;
    object-fit: contain;
}

.teaserDescription {
    font-size: small;
    text-wrap: normal;
}

@media (max-width: 1024px) {
    .teaserTileBox {
        width: 300px;
        height: 400px;
        margin-bottom: 1em;
        padding-bottom: 1em;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        box-shadow: var(--bg-shadowbox-box-shadow);
    }
}

@media (max-width: 768px) {

}

@media (max-width: 576px) {

}