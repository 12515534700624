@font-face {
  font-family: 'Megrim';
  src: url('../../../public/fonts/Megrim-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Comfortaa';
  src: url('../../../public/fonts/Comfortaa-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

h1 {
  font-family: "Megrim", system-ui;
  font-weight: 400;
  font-style: normal;
}

html body {
  margin: 0;
  font-family: "Comfortaa", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  caret-color: transparent;
}

