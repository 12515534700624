/*Authored by Tyler Smalley*/

.navbar {
    height: 0;
    width:0;
    position: relative;
}

.navbar.closed {
    padding: 0;
}

.navbar.showNav {
    padding: 0;
}

.navCircle {
    position: fixed;
    top: 2em;
    right: 2em;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    z-index: 9999;
    background: var(--brand-alpha-bg-gradient-accent1-medium-accent1-light);
    box-shadow: var(--bg-shadowbox-box-shadow);
    transition: all 0.3s ease 0s;
}

.navCircle:hover {
    background: var(--brand-bg-gradient-txt-tertiary-accent2-medium);
    color: var(--primary-hover-color);
    box-shadow: var(--bg-shadowbox-box-shadow-diffused);
    transform: var(--primary-hover-transform);
}

.navCircle:active {
    box-shadow: var(--bg-shadowbox-box-shadow);
    transform: var(--primary-active-transform);
}

.burger {
    position: relative;
    z-index: 9999;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: none;
    background: transparent;
    border: none;
    height: 100%;
    width: 100%;
    text-align: center;
}

.bar {
    background: var(--burger-bar-color);
    display: block;
    z-index: 9999;
    height: 6px;
    width: 50%;
    position: absolute;
    left: 25%;
    border-radius: 15px;
}

.burger .bar.top {
    top: 30%;
    animation: topBarInactive .75s forwards;
}

.burger .bar.middle {
    top: 50%;
    animation: middleBarInactive .75s forwards;
}

.burger .bar.bottom {
    top: 70%;
    animation: bottomBarInactive .75s forwards;
}

.burger.is-active .bar.top {
    animation: topBarActive .75s forwards;
}

.burger.is-active .bar.middle {
    animation: middleBarActive .75s forwards;
}

.burger.is-active .bar.bottom {
    animation: bottomBarActive .75s forwards;
}

/* Styles for the menu-wrap and menu */
.menuBox {
    font-family: 'Megrim', monospace;
    position: fixed;
    z-index: 9998;
    width: 0;
    height: 0;
    top: -500px;
    right: -500px;
    border-radius: 50%;
    transition: width .4s, height .4s;
    transform-origin: 50% 50%;
}

.backgroundImage {
    position: absolute;
    top: 0;
    right: 0;
    width: 150%;
    height: 150%;
    border-radius: 50%;
    background-image: url('../../../assets/img/components/navMenu/menuBg6-round.webp');
    background-size: contain;
    background-position: center;
    transition: width .4s, height .4s;
    transform-origin: 50% 50%;
}

.menu-wrap {
    display: none;
    width: 500px;
    height: 500px;
    position: absolute;
    text-align: center;
    right: 500px;
    top: 500px;
}

.horizontal-line {
    border-top: 1px solid var(--text-primary); /* You can update the color as per your need */
    width: 90%;
}

.link-list {
    list-style: none;
    font-size: 35px;
    line-height: 55px;
    letter-spacing: 2px;
    width: 50%;
    margin-top: -10px;
    margin-left: 50px;
}

.theme-list {
    list-style: none;
    font-size: 30px;
    line-height: 55px;
    letter-spacing: 2px;
    width: 60%;
    margin-top: -10px;
    margin-left: 50px;
}

.showNav .menu-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 500px;
}

.showNav .menuBox {
    width: 1000px;
    height: 1000px;
    box-shadow: var(--bg-shadowbox-box-shadow-diffused);
    animation: jello .75s ease-out .35s forwards;
}

.showNav .backgroundImage {
    width: 1000px;
    height: 1000px;
    animation: Menu-Image-Spin 50 1500s linear;
}

.showNav .link-list {

}

/* Media Queries */
@media (max-width: 992px) {
    .navCircle {
        width: 4em;
        height: 4em;
    }

    .bar {
        height: 0.25em;
    }

    .menu-wrap {
        width: 400px;
        height: 400px;
    }

    .showNav .menuBox, .showNav .backgroundImage {
        width: 900px;
        height: 900px;
    }

    .link-list {
        margin-top: -15px;
        font-size: 29px;
        line-height: 42px;
    }

    .theme-list {
        margin-top: -15px;
        font-size: 29px;
        line-height: 42px;
        width: 75%;
        margin-right: 20px;
    }

}

@media (max-width: 768px) {
    .bar {
        height: 0.2em;
    }

    .menu-wrap {
        width: 450px;
        height: 450px;
    }

    .showNav .menuBox, .showNav .backgroundImage {
        width: 950px;
        height: 950px;
    }

    .link-list {
        margin-top: -10px;
        font-size: 33px;
        line-height: 49px;
    }

}

@media (max-width: 576px) {
    .navCircle {
        top: 1em;
        right: 1em;
    }

    .modal-open .navCircle {
        display: none;
    }

    .menuBox {
        transform-origin: center center;
    }

    .showNav .menuBox {
        height: 1000px;
        width: 1000px;
        top: -500px;
        right: -500px;
    }

    .backgroundImage {
        transform-origin: center center;
        background-image: url('../../../assets/img/components/navMenu/menuBg6-round.webp');
    }

    .showNav .backgroundImage {
        height: 100%;
        width: 100%;
        animation: none;
    }

    .menu-wrap {
        position: absolute;
        top: 50%;
        left: 6%;
        width: 500px;
        height: 500px;
    }

    .link-list {
        padding: 0;
        margin: 0;
        font-size: 32px;
        line-height: 55px;
    }

    .theme-list {
        padding: 0;
        margin: 0;
        font-size: 32px;
        line-height: 55px;
    }

}

/* keyframes for animations */
@keyframes topBarActive {
    0%, 35% {
        top: 30%;
        transform: rotate(0);
    }
    50% {
        top: 50%;
        transform: rotate(0);
    }
    70% {
        transform: rotate(45deg);
    }
    100% {
        top: 50%;
        transform: rotate(45deg);
    }
}

@keyframes middleBarActive {
    0%, 50% {
        opacity: 1;
    }
    51%, 100% {
        opacity: 0;
    }
}

@keyframes bottomBarActive {
    0%, 35% {
        top: 70%;
        transform: rotate(0);
    }
    50% {
        top: 50%;
        transform: rotate(0);
    }
    70% {
        transform: rotate(-45deg);
    }
    100% {
        top: 50%;
        transform: rotate(-45deg);
    }
}

@keyframes topBarInactive {
    0%, 35% {
        top: 50%;
        transform: rotate(45deg);
    }
    50% {
        top: 50%;
        transform: rotate(0);
    }
    70% {
        top: 30%;
    }
    100% {
        top: 30%;
        transform: rotate(0);
    }
}

@keyframes middleBarInactive {
    0%, 50% {
        opacity: 0;
    }
    51%, 100% {
        opacity: 1;
    }
}

@keyframes bottomBarInactive {
    0%, 35% {
        top: 50%;
        transform: rotate(-45deg);
    }
    50% {
        top: 50%;
        transform: rotate(0);
    }
    70% {
        top: 70%;
    }
    100% {
        top: 70%;
        transform: rotate(0);
    }
}
