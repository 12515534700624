/*Authored by Tyler Smalley*/
@import url('/src/styles/site/_variables.css');
@import url('/src/styles/site/keyframes.css');

* {
    box-sizing: border-box;
}

*:before,
*:after {
    box-sizing: border-box;
}

/*Text Colors & Font*/
.text-brand-cream {
    color: var(--text-primary);
}

.text-brand-teal {
    color: var(--text-tertiary);
}

.text-brand-brown {
    color: var(--text-secondary);
}

.text-brand-font {
    font-family: 'Megrim', monospace;
}

.justify-text {
    text-align: justify;
}

/*Background Color Classes*/
.bg-see-thru {
    background: transparent;
}

.bg-shadowbox-brown {
    background-color: var(--bg-shadowbox-color);
}

.bg-brand-cream {
    background-color: var(--brand-bg-txt-primary);
}

.bg-brand-brown-dark {
    background-color: var(--brand-bg-txt-secondary);
}

.bg-brand-brown-dark-medium {
    background-color: var(--brand-accent1-dark-medium);
}

.bg-brand-brown-medium {
    background-color: var(--brand-accent1-medium);
}

.bg-brand-brown-medium-light {
    background-color: var(--brand-accent1-medium-light);
}

.bg-brand-brown-light {
    background-color: var(--brand-accent1-light);
}

.bg-brand-teal-dark {
    background-color: var(--brand-bg-txt-tertiary);
}

.bg-brand-teal-dark-medium {
    background-color: var(--brand-accent2-dark-medium);
}

.bg-brand-teal-medium {
    background-color: var(--brand-accent2-medium);
}

.bg-brand-teal-medium-light {
    background-color: var(--brand-accent2-medium-light);
}

.bg-brand-teal-light {
    background-color: var(--brand-accent2-light);
}


/*Background Color - RGB variants with Alpha*/
.bg-alpha-brand-cream {
    background-color: var(--brand-alpha-bg-txt-primary);
}

.bg-alpha-brand-brown-dark {
    background-color: var(--brand-alpha-bg-txt-secondary);
}

.bg-alpha-brand-brown-dark-medium {
    background-color: var(--brand-alpha-accent1-dark-medium);
}

.bg-alpha-brand-brown-medium {
    background-color: var(--brand-alpha-accent1-medium);
}

.bg-alpha-brand-brown-medium-light {
    background-color: var(--brand-alpha-accent1-medium-light);
}

.bg-alpha-brand-brown-light {
    background-color: var(--brand-alpha-accent1-light);
}

.bg-alpha-brand-teal-dark {
    background-color: var(--brand-alpha-bg-txt-tertiary);
}

.bg-alpha-brand-teal-dark-medium {
    background-color: var(--brand-alpha-accent2-dark-medium);
}

.bg-alpha-brand-teal-medium {
    background-color: var(--brand-alpha-accent2-medium);
}

.bg-alpha-brand-teal-medium-light {
    background-color: var(--brand-alpha-accent2-medium-light);
}

.bg-alpha-brand-teal-light {
    background-color: var(--brand-alpha-accent2-light);
}


/*Background Color Gradient Classes*/
.bg-gradient-cream {
    background: var(--brand-bg-gradient-primary);
}

.bg-gradient-cream-dark-brown {
    background: var(--brand-bg-gradient-secondary-primary);
}

.bg-gradient-dark-brown-medium-brown {
    background: var(--brand-bg-gradient-secondary-accent1-medium);
}

.bg-gradient-dark-brown-dark-medium {
    background: var(--brand-bg-gradient-txt-secondary-accent1-dark-medium);
}

.bg-gradient-dark-medium-medium-brown {
    background: var(--brand-bg-gradient-accent1-dark-medium-accent1-medium);
}

.bg-gradient-medium-brown-medium-light {
    background: var(--brand-bg-gradient-accent1-medium-accent1-medium-light);
}

.bg-gradient-medium-light-light-brown {
    background: var(--brand-bg-gradient-accent1-medium-light-accent1-light);
}

.bg-gradient-medium-brown-light-brown {
    background: var(--brand-bg-gradient-accent1-medium-accent1-light);
}

.bg-gradient-light-brown-dark-teal {
    background: var(--brand-bg-gradient-accent1-light-txt-tertiary);
}

.bg-gradient-dark-teal-dark-medium {
    background: var(--brand-bg-gradient-txt-tertiary-accent2-dark-medium);
}

.bg-gradient-dark-teal-medium-teal {
    background: var(--brand-bg-gradient-txt-tertiary-accent2-medium);
}

.bg-gradient-dark-medium-medium-teal {
    background: var(--brand-bg-gradient-accent2-dark-medium-accent2-medium);
}

.bg-gradient-medium-teal-medium-light {
    background: var(--brand-bg-gradient-accent2-medium-accent2-medium-light);
}

.bg-gradient-medium-teal-light-teal {
    background: var(--brand-bg-gradient-accent2-medium-accent2-light);
}

.bg-gradient-teal-cream {
    background: var(--brand-bg-gradient-accent2-txt-primary);
}

.bg-alpha-gradient-cream {
    background: var(--brand-alpha-bg-gradient-primary);
}

.bg-alpha-gradient-cream-dark-brown {
    background: var(--brand-alpha-bg-gradient-secondary-primary);
}

.bg-alpha-gradient-dark-brown-medium-brown {
    background: var(--brand-alpha-bg-gradient-secondary-accent1-medium);
}

.bg-alpha-gradient-dark-brown-dark-medium {
    background: var(--brand-alpha-bg-gradient-txt-secondary-accent1-dark-medium);
}

.bg-alpha-gradient-dark-medium-medium-brown {
    background: var(--brand-alpha-bg-gradient-accent1-dark-medium-accent1-medium);
}
.bg-alpha-gradient-medium-brown-medium-light {
    background: var(--brand-alpha-bg-gradient-accent1-medium-accent1-medium-light);
}
.bg-alpha-gradient-medium-light-light-brown {
    background: var(--brand-alpha-bg-gradient-accent1-medium-light-accent1-light);
}
.bg-alpha-gradient-medium-brown-light-brown {
    background: var(--brand-alpha-bg-gradient-accent1-medium-accent1-light);
}
.bg-alpha-gradient-light-brown-dark-teal {
    background: var(--brand-alpha-bg-gradient-accent1-light-txt-tertiary);
}
.bg-alpha-gradient-dark-teal-dark-medium {
    background: var(--brand-alpha-bg-gradient-txt-tertiary-accent2-dark-medium);
}
.bg-alpha-gradient-dark-teal-medium-teal {
    background: var(--brand-alpha-bg-gradient-txt-tertiary-accent2-medium);
}
.bg-alpha-gradient-dark-medium-medium-teal {
    background: var(--brand-alpha-bg-gradient-accent2-dark-medium-accent2-medium);
}
.bg-alpha-gradient-medium-teal-medium-light {
    background: var(--brand-alpha-bg-gradient-accent2-medium-accent2-medium-light);
}
.bg-alpha-gradient-medium-teal-light-teal {
    background: var(--brand-alpha-bg-gradient-accent2-medium-accent2-light);
}
.bg-alpha-gradient-teal-cream {
    background: var(--brand-alpha-bg-gradient-accent2-txt-primary);
}

/*Background Image Classes*/
.bg-brand-background-star-1 {
    background-image: url("../../assets/img/site/backgrounds/starfield1.webp");
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-brand-background-star-2 {
    background-image: url("../../assets/img/site/backgrounds/starfield2.webp");
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-brand-background-star-3 {
    background-image: url("../../../public/assets/pages/Home/starfield3.webp");
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-brand-background-star-1-vertical {
    background-image: url("../../assets/img/site/backgrounds/starfield1-vertical.webp");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.bg-brand-background-star-2-vertical {
    background-image: url("../../assets/img/site/backgrounds/starfield2-vertical.webp");
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-brand-background-star-3-vertical {
    background-image: url("../../assets/img/site/backgrounds/starfield3-vertical.webp");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.bg-brand-background-orb-1 {
    background-image: url('../../assets/img/components/hero/roundOrb1.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-brand-background-orb-2 {
    background-image: url('../../assets/img/components/hero/roundOrb2.png');
    background-repeat: no-repeat;
    background-size: cover;
}

/*Box Shadows*/
.brand-box-shadow {
    box-shadow: var(--bg-shadowbox-box-shadow);
}

.brand-box-shadow-diffused {
    box-shadow: var(--bg-shadowbox-box-shadow-diffused);
}

.brand-box-shadow-indent {
    box-shadow: var(--bg-shadowbox-box-shadow-indent);
}

.brand-box-shadow-indent-diffused {
    box-shadow: var(--bg-shadowbox-box-shadow-indent-diffused);
}

/*Utility Classes*/
.privacy-screen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
}

.sr-only {
    display: none;
}

.fullWidth {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.hideOverflow {
    overflow: hidden;
}

.clip-overflow-x {
    overflow-x: clip;
}

.halfPageWrapper {
    width: 100%;
    min-height: 50vh;
    height: fit-content;
}

.fullPageWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    height: fit-content;
}

.pageLayout {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.wideFlexContainer {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 100%;
    min-height: 100vh;
    width: max-content;
}

.contentWrapper-50 {
    display: flex;
    justify-content: center;
    max-width: 50vw;
    width: 100%;
    padding: 2em;
}

.contentWrapper-column-50 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 50vw;
    width: 100%;
    padding: 2em;
}

.contentWrapper-row-50 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    max-width: 50vw;
    width: 100%;
    padding: 2em;
}

.contentWrapper-75 {
    display: flex;
    justify-content: flex-start;
    max-width: 75vw;
    width: 100%;
    padding: 2em;
}

.contentWrapper-column-75 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 75vw;
    width: 100%;
    padding: 2em;
}

.contentWrapper-row-75 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    max-width: 75vw;
    width: 100%;
    padding: 2em;
}

/*accordionContent should go here!!!*/

.overflow-auto {
    overflow: auto;
}

.pageTitleFontSize {
    font-size: 24pt;
}

.headerFontSize {
    font-size: 20pt;
}

.defaultFontSize {
    font-size: 16pt;
}

.secondaryFontSize {
    font-size: 14pt;
}

.captionFontSize {
    font-size: 12pt;
}

.imgCircle {
    border-radius: 50%;
}

.opacity-0 {
    opacity: 0;
}

.opacity-10 {
    opacity: 10%;
}

.opacity-20 {
    opacity: 20%;
}

.opacity-30 {
    opacity: 30%;
}

.opacity-40 {
    opacity: 40%;
}

.opacity-50 {
    opacity: 50%;
}

.opacity-60 {
    opacity: 60%;
}

.opacity-70 {
    opacity: 70%;
}

.opacity-80 {
    opacity: 80%;
}

.opacity-90 {
    opacity: 90%;
}

.opacity-100 {
    opacity: 100%;
}

.backdropBlur::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    z-index: 9990;
}

.targetedBlur::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    z-index: 9980;
}

.bottom-blur {
    mask-image: linear-gradient(to top, rgba(0, 0, 0, 0.8) 25%, transparent);
    backdrop-filter: blur(20px);
    opacity: 0.9;
    width: 100%;
    height: clamp(85px, 10vh, 190px);
    bottom: 0;
    pointer-events: none;
    position: fixed;
    z-index: 9991;
}


.z-negative {
    z-index: -99 !important;
}

.z-1 {
    z-index: 1;
}

.z-2 {
    z-index: 2;
}

.z-3 {
    z-index: 3;
}

.z-4 {
    z-index: 4;
}

.z-5 {
    z-index: 5;
}

.z-6 {
    z-index: 6;
}

.z-7 {
    z-index: 7;
}

.z-8 {
    z-index: 8;
}

.z-9 {
    z-index: 9;
}

.z-10 {
    z-index: 10;
}

.z-top {
    z-index: 99999;
}

.stickyBox {
    position: sticky;
    top: 0;
}

.hide {
    visibility: hidden !important;
}

#contactAccordion {
    position: relative;
    z-index: 9;
    background-color: var(--brand-bg-txt-primary);

    /* add this line to hint the browser to optimize the element */
    will-change: transform;
}

/*ANIMATIONS*/
.fadeEffect-fast {
    opacity: 1;
    transition: opacity .5s ease-out;
}

.fadeEffect {
    opacity: 1;
    transition: opacity 1s ease-in-out;
}

.fadeEffect-slow {
    opacity: 1;
    transition: opacity 3s ease-in-out;
}

.fadeOut {
    opacity: 0;
}

.planetarySpin {
    animation: Menu-Image-Spin 50 500s linear;
}


/*Reusable Components*/
.linkButton {
    display: inline-block;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 18pt;
    letter-spacing: 1px;
    text-align: center;
    padding: 1rem;
    margin-top: 1rem;
    background: var(--brand-bg-txt-primary);
    color: var(--brand-accent2-medium);
    border: none;
    border-radius: 30px;
    box-shadow: var(--bg-shadowbox-box-shadow);
    transition: all 0.3s ease 0s;
    cursor: pointer;
}

.no-style-button {
    border: none;
    padding: 0;
    background: none;
    outline: none;
    font-family: inherit;
    font-size: inherit;
}

.linkButton:hover {
    background: var(--brand-bg-gradient-txt-tertiary-accent2-medium);
    color: var(--primary-hover-color);
    box-shadow: var(--bg-shadowbox-box-shadow-diffused);
    transform: var(--primary-hover-transform);
}

.linkButton:active {
    box-shadow: var(--bg-shadowbox-box-shadow);
    transform: var(--primary-active-transform);
}

.nav-item {
    background: var(--brand-alpha-bg-gradient-accent1-medium-accent1-light);
    color: var(--text-primary);
    box-shadow: var(--bg-shadowbox-box-shadow);
    margin-bottom: 6px;
    border-radius: 30px;
    will-change: transform, opacity;
    transition: all 0.3s ease 0s;
}

.nav-item:hover {
    background: var(--brand-bg-gradient-txt-tertiary-accent2-medium);
    color: var(--primary-hover-color);
    box-shadow: var(--bg-shadowbox-box-shadow-diffused);
    transform: var(--primary-hover-transform);
}

.nav-item:active {
    box-shadow: var(--bg-shadowbox-box-shadow);
    transform: var(--primary-active-transform);
}

.nav-link {
    text-decoration: none;
    color: var(--text-primary);
    padding: .4rem;
}

.border-rounded {
    border-radius: 15px;
}

.border-rounded-top{
    border-top-left-radius: 15%;
    border-top-right-radius: 15%;
}

.border-rounded-bottom{
    border-bottom-left-radius: 15%;
    border-bottom-right-radius: 15%;
}

.border-circle{
    border-radius: 50%;
}

.border-circle-top{
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
}

.border-circle-bottom{
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
}

.border-none {
    border: none;
}

/* Media Queries */
@media (max-width: 1440px) {
    .contentWrapper-50, .contentWrapper-column-50, .contentWrapper-row-50 {
        max-width: 80vw;
    }
    .contentWrapper-75, .contentWrapper-column-75, .contentWrapper-row-75 {
        max-width: 100vw;
    }

    .pageTitleFontSize {
        font-size: 22pt;
    }

    .headerFontSize {
        font-size: 18pt;
    }

    .defaultFontSize {
        font-size: 14pt;
    }

    .secondaryFontSize {
        font-size: 12pt;
    }

    .captionFontSize {
        font-size: 10pt;
    }

    .nav-link{
        padding: .3rem;
    }
}

@media (max-width: 768px) {
    .contentWrapper-50, .contentWrapper-column-50, .contentWrapper-row-50 {
        max-width: 95vw;
    }
    .contentWrapper-75, .contentWrapper-column-75, .contentWrapper-row-75 {
        max-width: 100vw;
    }

    .pageTitleFontSize {
        font-size: 22pt;
    }

    .no-sticky-sm {
        position: static !important;
    }
    .no-z-sm {
        z-index: auto !important;
    }

    .nav-link{
        padding: .3rem;
    }
}

@media (max-width: 576px) {
    .contentWrapper-50, .contentWrapper-75, .contentWrapper-column-50, .contentWrapper-column-75, .contentWrapper-row-50, .contentWrapper-row-75 {
        max-width: 100vw;
        flex-direction: column;
        padding: 10px;
    }

    .pageTitleFontSize {
        font-size: 20pt;
    }

    .bg-brand-background-star-1-vertical {
        background-size: cover;
    }

    .bg-brand-background-star-2-vertical {
        background-size: cover;
    }

    .bg-brand-background-star-3-vertical {
        background-size: cover;
    }

    .nav-item {
        transition: none;
    }

    .nav-link{
        padding: .4rem;
    }
}

/*Accessibility*/
:focus-visible {
    outline: 2px solid blue;
}

@media (prefers-reduced-motion: reduce) {
    *,
    ::before,
    ::after {
        animation-duration: 0.001s !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.001s !important;
    }
}